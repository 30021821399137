@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

body{
    font-family: "Russo One", sans-serif;
    color: white;
    -webkit-tap-highlight-color: transparent;
}


:root{
    --purple: #540351;
    --blue: #3627b1;
    --cyan: #25afce;
    --transparent: #00000000;
    --radius-left: 3rem 0 0 3rem;
    --radius-right: 0 3rem 3rem 0;
}

button:hover,
.class-sub-container a:hover,
.project-item:hover,
.skill-item:hover,
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-3:not(.active-subheading):hover {
    background-image: linear-gradient(90deg, #ffffff00, #ffffff42 50%, #ffffff00);
}