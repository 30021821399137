.contact-menu{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Cv{
    position: absolute;
    top: 25%;
    left: 49%;
    transform: translate(-50%, -50%);
}

.orMessageMe{
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    text-align: center;
}

form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 18.5rem;
}

.form-group{
    width: 100%;
}

input, #message{
    width: 100%;
    background-color: #00000040;
    border-radius: 0.3rem;
    border: 0.07rem solid rgba(255, 255, 255, 0.4);
    outline: none;
    color: white;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Russo One", sans-serif;
}

input{
    height: 1.9rem;
}

::placeholder{
    color: white;
    font-weight: 400;
}

#message{
    height: 6.2rem;
    resize: none;
}

button {
    color: white;
    font-size: 1rem;
    font-weight: 600;
    width: 102%;
    padding: 0.5rem 0;
    background-color: transparent;
    border: none;
    font-family: "Russo One", sans-serif;
    cursor: pointer;
}

.success-message{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #00000030;
    width: 100%;
    height: 12.5rem;
    border-radius: 0.3rem;
    border: 0.07rem solid rgba(255, 255, 255, 0.7);
}

.success-message p{
    margin: 0.5rem;
    padding: 0 3.7rem;
}

.error-message{
    padding: 0.3rem;
    color: white;
    background-color: rgb(255, 59, 59);
    border-radius: 0.2rem;
}

label{
    display: none;
}

.buttonCv a{
    font-size: 25px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding: 10%;
    text-decoration: none; 
    color: white;
    text-align: center;
}
.buttonCv a:hover{
    padding: 12%;
}


@media (max-width: 1100px){
    .contact-menu{
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .Cv{
        position: absolute;
        top: 20%;
        left: 49%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
    
    .orMessageMe{
        position: absolute;
        top: 32%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        text-align: center;
    }
    form{
        width: 100%;
    }
    input, #message{
        width: 92%;
    }
    .success-message p{
        padding: 0 0.5rem;
    }
    .buttonCv a{
        /* margin-top: 15% !important; */
        font-size: 1rem !important;
        border-radius: 20px;
        width: 100%;
        padding: 10%;
        /* margin-bottom: 0%; */
        text-decoration: none; 
        color: white;
        text-align: center;
    }
    .buttonCv:hover a{
        padding: 13%;
    }
    .submit{
        margin-top: -8%;
    }
}

@media (max-width: 700px){
    .contact-menu{
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .Cv{
        position: absolute;
        top: 20%;
        left: 48%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
    
    .orMessageMe{
        position: absolute;
        top: 31%;
        left: 49%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        text-align: center;
    }
    form{
        width: 100%;
    }
    input, #message{
        width: 92%;
    }
    .success-message p{
        padding: 0 0.5rem;
    }
    .buttonCv a{
        /* margin-top: 15% !important; */
        font-size: 1rem !important;
        border-radius: 20px;
        width: 100%;
        padding: 10%;
        /* margin-bottom: 0%; */
        text-decoration: none; 
        color: white;
        text-align: center;
    }
    .buttonCv:hover a{
        padding: 13%;
    }
    .submit{
        margin-top: -8%;
    }
}

/* @media screen and (orientation: landscape) {
    .contact-menu{
        position: absolute;
        top: 52%;
        left: 65%;
    }
    
    .Cv{
        position: absolute;
        top: 35%;
        left: 29%;
    }
    
    .orMessageMe{
        position: absolute;
        top: 55%;
        left: 31%;
    }
    form{
        width: 100%;
    }
    input, #message{
        width: 92%;
    }
    .success-message p{
        padding: 0 0.5rem;
    }
    .buttonCv a{
        font-size: 1rem !important;
        border-radius: 20px;
        width: 100%;
        padding: 10%;
        text-decoration: none; 
        color: white;
        text-align: center;
    }
    .buttonCv:hover a{
        padding: 13%;
    }
    .submit{
        margin-top: -8%;
    }

    form{
        gap: 1rem !important;
    }

    input, #message{
        padding: 0.2rem;
    }

    #message{
        height: 1.9rem;
        resize: none;
    }
} */