.project-menu{
    width: 55rem;
    position: fixed;
    display: flex;
    top: 23%;
    left: 45%;
    transform: translateX(-50%);
}

.project-items-container{
    flex-direction: column;
    display: flex;
    position: relative;
    width: 40%;
    margin-top: 6rem;
    align-items: flex-end;
}

.project-item{
    height: 3.75rem;
    width: 90%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.project-item.activeProject .title{
    font-size: 1.5rem;
}

.activeProject{
    border-radius: var(--radius-left);
    cursor: default;
}

.project-item.activeProject:nth-child(1){
    background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
}
.project-item.activeProject:nth-child(2){
    background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
}
.project-item.activeProject:nth-child(3){
    background: linear-gradient(270deg, #ffffff00, var(--cyan) 70%);
}
.project-item.activeProject:nth-child(4){
    background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
}

.project-sub-container{
    width: 60%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.project-sub-container h3{
    position: relative;
    margin: 0;
}

.project-sub-container img{
    width: 100%;
    margin-top: 0.5rem;
    object-fit: cover;
    border-radius: 0.5rem 3rem 0.5rem 0.5rem;
    opacity: 0.9;
}

.project-sub-container p{
    font-size: 1rem;
}

.link-container a{
   color: white;
   text-decoration: none; 
   margin-top: 1rem;
   width: 50%;
   height: 2.75rem;
   text-align: center;
}

.link-container{
    display: flex;
    justify-content: space-around;
}

@media (max-width: 1100px){
    .project-menu{
        width: 28rem;
        top: 20%;
        transform: translateX(-50%);
        left: 50%;
    }
    .project-item{
        height: 2rem;
        width: 100%;
    }
    .project-item.activeProject .title{
        font-size: 1rem;
    }
    .title{
        font-size: 0.8rem;
    }
}

@media (max-width: 700px){
    .project-menu{
        width: 90%;
        flex-direction: column;
        align-items: center;
        left: 50%;
        top: 15%;
    }
    .project-item.activeProject .title{
        width: 100%;
        font-size: 0.9rem;
        text-align: center;
    }
    .title{
        font-size: 0.8rem;
        text-align: center;
    }
    .project-menu p{
        font-size: 1rem   ;
    }
    .project-sub-container{
        margin-top: 0.3rem;
        width: 100%;
        font-size: 1.2rem;
        background: linear-gradient(90deg, rgba(0,0,0,0.25), rgba(255, 255, 255, 0)) !important;
        border-radius: 20px;
        padding: 3%;
    }
    .project-sub-container h3{
        font-size: 1.1rem;
        text-align: center;
    }
    .project-sub-container img{
        width: 100%;
    }
    .link-container a{
        margin-top: 0.5rem;
        width: 50%;
        height: 2rem;
    }
    .project-items-container{
        margin-top: 1%;
        margin-bottom: 5%;
        width: 100%;
        left: 0%;
        padding-bottom: 0%;
        flex-direction: row;
    }
    .project-item{
        height: 3rem;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
    }
    .project-item.activeProject{
        width: 100%;
    }
    
    .activeProject{
        border-radius: 20px;
        cursor: default;
        padding-left: 2%;
    }
}

/* @media screen and (orientation: landscape) {
    .project-items-container{
        flex-direction: column;
        margin-top: 7%;
    }
    .project-item.activeProject{
        width: 20%;
        font-size: 0.9rem;
        text-align: center;
    }
    .project-sub-container{
        margin-top: -14rem;
        width: 50%;
        font-size: 0.7rem;
        border-radius: 20px;
        padding: 1%;
    }
    .project-sub-container h3{
        font-size: 0.7rem;
    }
    .project-sub-container p{
        font-size: 0.7rem;
    }
    .project-sub-container img{
        width: 50%;
        margin-left: 25%;
    }
    .link-container{
        margin-top: -1rem;
    }
} */