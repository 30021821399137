.skill-menu{
    width: 31.2rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 30%;
    right: 15vw;
    align-items: flex-end;
}

.skill-item{
    height: 3.7rem;
    width: 50%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.skill-title{
    font-size: 1rem;
    padding-right: 4.5rem;
}

.skill-item.activeSkill .skill-title{
    font-size: 1.5rem;
}

.activeSkill{
    border-radius: var(--radius-left);
    cursor: default;
}

.skill-item.activeSkill:nth-child(1){
    background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
}

.skill-item.activeSkill:nth-child(2){
    background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
}

.skill-icon{
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 1.8rem;
    left: 8rem;
}

.skill-sub-container-1 h3,
.skill-sub-container-2 h3{
    position: relative;
    margin: 0;
}

.menu{
    width: 19rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-25%);
    right: 15vw;
}

.skill-sub-container-1,
.skill-sub-container-2{
    display: flex;
    justify-content: right;
    gap: 2rem;
}

.level-container{
    display: flex;
    gap: 0.3rem;
}

.level-point{
    width: 3rem;
    height: 2rem;
    border-radius: 0.2rem;
}

.filled{
    background: linear-gradient(180deg, #044105, #0b940d, #14ff18)
}

.unfilled{
    background: linear-gradient(180deg, #000000, #771f1f, #9d0000)
}

.skill-sub-container{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

@media (max-width: 1100px){
    .skill-item.activeSkill .skill-title{
        font-size: 1rem;
    }
    .skill-icon{
        display: none;
    }
    .level-point{
        width: 1.2rem;
        height: 1rem;
        border-radius: 0.1rem;
    }
    .level-container{
        gap: 0.5rem;
    }
    .skill-sub-container-1, .skill-sub-container-2{
        gap: 0.5rem;
    }
    .skill-sub-container{
        margin-top: 2rem;
        gap: 0.2rem;
    }
    h1{
        font-size: 0.8rem;
    }
    h2, h3, p{
        font-size: 0.7rem;
    }
    .skill-menu{
        flex-direction: column;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        top: 30%;
    }
    .skill-item{
        height: 2rem;
        width: 12rem;
    }
}

@media (max-width: 700px){
    .menu{
        width: 10rem;
        top: 30%;
        left: 50%;
        transform: translateY(-50%);
    }
    .skill-item.activeSkill .skill-title{
        font-size: 1rem;
    }
    .skill-icon{
        display: none;
    }
    .level-point{
        width: 2rem;
        height: 2rem;
        border-radius: 0.1rem;
    }
    .level-container{
        gap: 0.5rem;
    }
    .skill-sub-container-1, .skill-sub-container-2{
        gap: 0.5rem;
    }
    .skill-sub-container{
        margin-top: 2rem;
        gap: 0.3rem;
        background: linear-gradient(90deg, rgba(0,0,0,0.25), rgba(255, 255, 255, 0)) !important;
        border-radius: 20px;
        padding: 5%;
    }
    h1{
        font-size: 0.8rem;
    }
    h2, h3, p{
        font-size: 0.7rem;
    }
    .skill-menu{
        flex-direction: column;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        top: 18%;
    }
    .skill-item{
        height: 3rem;
        width: 12rem;
        margin-right: -55%;
    }
    .skill-item.activeSkill h2{
        font-size: 3rem;
    }
}

/* @media screen and (orientation: landscape) {
    .skill-menu{
        left: 55%;
    }
    .skill-item{
        height: 3rem;
        width: 12rem;
        margin-right: -90%;
    }
    .skill-sub-container{
        margin-top: -3rem;
        gap: 0.2rem;
        padding: 3%;
        margin-left: -18%;
    }
    .skill-sub-container h3{
        font-size: 0.6rem;
        padding-right: 2%;
        width: 100%;
    }
    .skill-sub-container-1, .skill-sub-container-2{
        gap: 0rem;
    }
    .level-point{
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 0.1rem;
    }
} */